<template>
  <vue-timeline-update v-if="event"
                       :date-string="dateString"
                       :date="date"
                       :title="event.Title"
                       :description="notes"
                       :thumbnail="img"
                       :category="event.Status"
                       :icon="event.Icon"
                       :color="event.Color"
  />
</template>

<script>
import {format} from "timeago.js";

export default {
  name: 'Event',
  props: {
    event:Object
  },
  computed:{
    date(){
      return new Date(this.event.Date)
    },
    notes(){
      if(!this.event.Notes) return ""
      return this.event.Notes.replace(new RegExp(/\r?\n/,'g'), '<br />')
    },
    dateString(){
      if(this.event.Status === 'A venir') return 'A venir'
      return format(new Date(this.event.Date), 'fr_FR')
    },
    img(){
      return this.event && this.event.Attachments && this.event.Attachments[0].thumbnails.large.url
    }
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
