<template>
  <div id="app" v-if="projectKey && !loading">
    <div class="project">
      <div :style="logoCss" class="img"></div>
      <h1>{{ project.Name }}</h1>
      <p>{{ project.Notes }}</p>
      <div class="status">{{ project.Status }}</div>
    </div>
    <div>
      <Step :id-project="projectKey"></Step>
    </div>
  </div>
  <div v-else-if="!projectKey && !loading" class="project">
    <form class="search" @submit.prevent="loadData(projectSearch)">
      <label for="projectId">Identifant de votre projet,<br> <i> (vous l'avez reçu par e-mail)</i> </label>
      <div class="error" v-if="error">Ce projet n'existe pas.</div>
      <input type="text" name="projectId" id="projectId" v-model="projectSearch">
      <button type="submit">Rechercher</button>
    </form>
  </div>
  <div class="project" v-else>
    <div class="loader"></div>
  </div>


</template>

<script>
import {API_URL} from '@/config'
import Step from "@/components/Step";

export default {

  name: 'Project',
  props: {
    idProject: String
  },
  components: {
    Step
  },
  data() {
    return {
      projectKey: undefined,
      projectSearch: undefined,
      error: false,
      loading: false,
      project: {
        Name: '',
        Notes: ''
      }
    }
  },
  methods: {
    async loadData(idProject) {

      if (!idProject) {
        return
      }
      this.error = false
      this.loading = true


      let data = (await this.$api.get({idProject})).body
      this.loading = false
      if (!data.id) {
        this.error = true
        return
      }
      this.projectKey = idProject
      this.project = data.fields


    }
  },
  computed: {
    logoCss() {
      if (!this.project || !this.project.Attachments) return
      return {
        backgroundImage: `url(${this.project.Attachments[0].thumbnails.large.url})`
      }
    }
  },
  watch:{
    idProject(newVal){
      return this.loadData(newVal)
    }
  },
  mounted() {
    this.$api = this.$resource(API_URL + '/projects{/idProject}');
    //this.loadData(this.idProject)
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
#app {
  display: flex;
  flex-direction: row;
}

.project {
  position: fixed;
  margin-top: 150px;
  padding: 50px 1rem 1rem 1rem;
  left: 1rem;
  width: 300px;
  display: block;
  background-color: var(--main-bg-color);
  color: var(--main-color);
  border-radius: 5px;
  text-align: center;
}

.search {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.search > * {
  padding: .5rem;
  margin: .5rem;
}

.search button {
  background-color: rgba(0, 173, 255, .85);
  border: none;
  border-radius: 5px;
  padding: .5rem 1rem;
  color: white;
}

.error {
  color: rgba(255, 0, 0, .7);
}

.img {
  position: absolute;
  top: -60px;
  left: 50%;
  transform: translateX(-50%);
  height: 100px;
  width: 100px;
  border-radius: 50%;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-color: white;
}

.status {
  background-color: white;
  border-radius: 0.5em;
  margin: auto;
  padding: 0 1rem;
  color: black;
  width: fit-content;
}

</style>
