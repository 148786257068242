<template>
    <!--    <img alt="Vue logo" src="./assets/logo.png">-->
    <Project :id-project="projectId"></Project>


</template>

<script>
import Project from "@/components/Project";



export default {
  name: 'App',
  components: {
    Project
  },
  data() {
    return {
      projectId: undefined
    }
  },
  methods: {},
  mounted() {
    let split = window.location.href.split('/')
    if(split.length <= 3) return
    this.projectId = split[split.length - 1];


  }
}
</script>

<style>

:root {
  --main-bg-color: rgba(0, 0, 0,.7);
  --main-color: #fff;
}


body{
  font-family: 'Roboto', sans-serif;
}



body{
  background-image: url("/img/fond.jpg");
  background-repeat: no-repeat;
  background-position: center right;
  background-size: cover;
  background-attachment: fixed;
  min-height: 100vh;
  margin: 0;
}
</style>
