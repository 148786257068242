<template>
  <div class="steps">
    <div class="step" v-for="step in steps" :key="step.id" @click="open(step)">
      <div class="title" :class="{'click': canOpen(step)}">
        <h2>
          {{ step.fields.Name }}
        </h2>
        <div>
          <div class="status">{{ step.fields.Status }}</div>
          <i v-if="canOpen(step)" class="gb-base-number__icon gb-base-icon" :class="{'open': step.open}">expand_more</i>
        </div>
      </div>

      <div v-if="step.open" class="content">
        <Events :step-id="step.id"></Events>
      </div>

    </div>
  </div>
</template>

<script>
import {API_URL} from "@/config";
import Events from "./Events";

export default {
  name: 'Step',
  props: {
    idProject: String
  },
  components: {
    Events
  },
  data() {
    return {
      steps: []
    }
  },
  computed:{

  },
  methods: {
    canOpen(step){
      return step.fields.Status !=='A venir';
    },
    open(step) {
      if (step.open || !this.canOpen(step)) return
      for (let s of this.steps) {
        s.open = false;
      }
      step.open = true
    },
    async loadDataSteps(idProject) {
      let steps = (await this.$apiSteps.get({idProject})).body
      console.log(steps);
      for (let step of steps) {
        step.open = step.fields.Status === 'En cours';
      }
      this.$set(this, 'steps', steps)
    }
  },
  mounted() {
    this.$apiSteps = this.$resource(API_URL + '/projects{/idProject}/steps');
    this.loadDataSteps(this.idProject)
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.steps {
  display: flex;
  flex-direction: column;
  margin-left: 350px;
}

.step {
  position: relative;
  background-color: var(--main-bg-color);
  border-radius: 5px;
  margin: 1rem;
  max-width: 900px;
  min-width: 900px;
  padding: 1rem;
}

.title, .title > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--main-color);
}

.click{
  cursor: pointer;
}
h2{
  margin: 0;
}

.status {
  background-color: white;
  border-radius: 0.5em;
  margin: 1rem;
  padding: 0 1rem;
  color: black;
  position: absolute;
  top: -26px;
  right: 0;
}


.gb-base-icon {
  transition: transform 200ms ease-in-out;
}

.open {
  transform: rotateZ(180deg);
}

.content{
  margin-top: 1rem;
}
</style>
