import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import './timeago_fr'
import VueTimeline from "@growthbunker/vuetimeline";
import VueResource from "vue-resource";

Vue.use(VueResource)

Vue.use(VueTimeline, {
  // Specify the theme to use: dark or light (dark by default).
  theme: "dark",
});

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
}).$mount('#app')
