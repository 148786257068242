import {register} from "timeago.js";

const locale = function(number, index) {
    return [
        ["aujourd'hui","aujourd'hui"],
        ["aujourd'hui","aujourd'hui"],
        ["aujourd'hui","aujourd'hui"],
        ["aujourd'hui","aujourd'hui"],
        ["aujourd'hui","aujourd'hui"],
        ["aujourd'hui","aujourd'hui"],
        ['il y a 1 jour', 'dans 1 jour'],
        ['il y a %s jours', 'dans %s jours'],
        ['il y a 1 semaine', 'dans 1 semaine'],
        ['il y a %s semaines', 'dans %s semaines'],
        ['il y a 1 mois', 'dans 1 mois'],
        ['il y a %s mois', 'dans %s mois'],
        ['il y a 1 an', 'dans 1 an'],
        ['il y a %s ans', 'dans %s ans'],
    ][index];
}

register('fr_FR', locale);
