<template>
  <div>
    <div class="loader" v-if="loading"></div>
  <Event v-for="event in events" :key="event.id"
     :event="event.fields"
  />
  </div>
</template>

<script>
import {API_URL} from "@/config";
import Event from "./Event";

export default {
  name: 'Events',
  components:{
    Event
  },
  props: {
    stepId:String
  },
  data(){
    return {
      events : [],
      loading : true
    }
  },
  methods:{
    async loadDataTasks(idStep){
      this.$set(this, 'events', (await this.$apiTasks.get({idStep})).body)
      this.loading = false
    },
  },
  mounted() {
    this.$apiTasks = this.$resource(API_URL + '/steps{/idStep}/tasks');
    this.loadDataTasks(this.stepId)
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

.loader,
.loader:before,
.loader:after {
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: load7 1.8s infinite ease-in-out;
  animation: load7 1.8s infinite ease-in-out;
}
.loader {
  color: #ffffff;
  font-size: 10px;
  margin: 80px auto;
  position: relative;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.loader:before,
.loader:after {
  content: '';
  position: absolute;
  top: 0;
}
.loader:before {
  left: -3.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loader:after {
  left: 3.5em;
}
@-webkit-keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
@keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}

</style>
